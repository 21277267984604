import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/backgroundSection';
import {
  Section,
  Card,
  Title,
  Text,
  Row,
  List,
  SectionHeader,
  SectionHeaderTitle,
  HeaderAnimation,
  HeadingType,
  ButtonLink,
} from '../components/components';
import usePosition from '../components/usePosition';
import Fade from 'react-reveal/Fade';
import SVGImage from '../components/SVGImage';
import styled from 'styled-components';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const HeaderTextContainer = styled.div`
  @media (max-width: 576px) {
    padding: 0px 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  .btn-link {
    width: 250px;
  }
`;

const CustomSection = styled(Section)`
  .padding-top-36 {
    padding-top: 36px;
  }
  .padding-top-80 {
    padding-top: 80px;
  }
  .padding-bottom-24 {
    padding-bottom: 24px;
  }
  .list-dashed {
    list-style: none;
    margin: 0;
  }

  @media (max-width: 576px) {
    .decoration-container {
      height: 665px;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  @media (min-width: 576px) and (max-width: 992px) {
    .decoration-container {
      height: 660px;
      display: inline-flex;
    }
    .decoration-container-img {
      margin: 40% 0px;
      padding: 0px 45px 0px 0px;
    }
  }
  @media (min-width: 992px) {
    .decoration-container {
      height: auto;
      display: inline-flex;
    }
    .decoration-container-img {
      margin: 55% 0px;
    }
  }

  .decoration-container-right {
    height: 600px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const BackgroundContainer = styled.div`
  background-image: url(../../img/decoration-left-lightblue.svg);
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  width: 677px;
  height: 643px;
  position: absolute;
  left: 0;
  @media (max-width: 576px) {
    width: 659px;
    height: 722px;
  }
  @media (min-width: 992px) {
    width: 874px;
    height: 960px;
  }

  @media (min-width: 576px) and (max-width: 992px) {
    width: 874px;
    height: 760px;
  }
`;
const BackgroundContainerRight = styled.div`
  background-image: url(../../img/decoration-right-lightblue.svg);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 677px;
  height: 643px;
  position: absolute;
  right: 0;
  @media (max-width: 576px) {
    width: 515px;
    height: 722px;
    top: 45%;
  }
  @media (min-width: 992px) {
    width: 874px;
    height: 960px;
  }

  @media (min-width: 576px) and (max-width: 992px) {
    width: 674px;
    height: 660px;
  }
`;

const RowRight = styled.div`
  margin: 150px 0px;
  @media (max-width: 768px) {
    margin: 0px;
  }
  @media (min-width: 768px) {
    .margin-top-md-60 {
      margin-top: 60px;
    }
  }
  .margin-top-0 {
    margin-top: 0px;
  }
  .decoration-right-img {
    width: -webkit-fill-available;
  }
`;

const RowLeft = styled.div`
  margin-bottom: 150px;
  @media (max-width: 768px) {
    margin: 0px;
  }
  .margin-top-0 {
    margin-top: 0px;
  }
  @media (min-width: 768px) {
    .margin-top-md-100 {
      margin-top: 100px;
    }
  }
  .decoration-left-img {
    width: -webkit-fill-available;
  }
`;

const Services = ({ location }) => {
  const { scrollPosition } = usePosition();
  const breakpoints = useBreakpoint();
  return (
    <Layout
      navbarBackground={`${scrollPosition > 730 ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}`}
      navbarColor={`${scrollPosition > 730 ? '#3C4356' : 'white'}`}
    >
      <SEO title="Services" />
      <SectionHeader>
        <SectionHeaderTitle>
          <HeaderTextContainer className="w-100">
            <Title
              heading={HeadingType.H1}
              color="white"
              align="center"
              type="shadow"
              weight="300"
              font="Kollektif"
              className="d-flex flex-wrap justify-content-center"
            >
              <strong>Hand-Crafted projects</strong>
              for a wide range of needs
            </Title>
            <ButtonContainer className="mt-5 d-flex justify-content-center">
              <ButtonLink to="/contacts/" className="btn-white fit-content" title="Contact us">
                Let us craft your project!
              </ButtonLink>
            </ButtonContainer>
          </HeaderTextContainer>
        </SectionHeaderTitle>
        <HeaderAnimation cookieKey="services-header-loaded">
          <Hero
            fileName="image-services-cover@2x.jpg"
            ariaLabel="Hand-Crafted projects"
            tag="div"
          />
        </HeaderAnimation>
      </SectionHeader>

      <CustomSection
        tabletPadding="35px 0px"
        padding="0px"
        mobilePadding="0px"
        className="custom-container"
      >
        <RowLeft className="row position-relative h-100 d-md-flex gutt-16 g-0 outt-40">
          <div className="decoration-container col-8 col-md-3 col-lg-5 order-2 order-md-1">
            <BackgroundContainer role="img" aria-label="trapezoid background" />
            <div className="decoration-container-img col-8 col-md-12 col-lg-12">
              <Fade left>
                <SVGImage
                  src={`services-web.svg`}
                  className="decoration-left-img"
                  alt="Web services"
                  height="auto"
                  width="auto"
                  style={{
                    backgroundPositionX: breakpoints.sm ? 'center' : 'left',
                    backgroundSize: 'auto 100%',
                    margin: 'auto',
                    height: breakpoints.md ? (breakpoints.sm ? 254.26 : 197) : 327,
                  }}
                />
              </Fade>
            </div>
          </div>
          <div className="col-8 col-md-5 col-lg-5 offset-lg-1 order-1 order-md-2">
            <Fade bottom>
              <div>
                <Title
                  className="padding-top-80"
                  weight="700"
                  desktopsize="61px"
                  tabletsize="62px"
                  mobilesize="36px"
                  lineheightdesktop="61px"
                  lineheighttablet="62px"
                  lineheightmobile="43.2px"
                >
                  Hand-Crafted
                </Title>
                <Title
                  weight="400"
                  desktopsize="61px"
                  tabletsize="62px"
                  mobilesize="36px"
                  lineheightdesktop="61px"
                  lineheighttablet="62px"
                  lineheightmobile="43.2px"
                >
                  Web Solutions
                </Title>
                <Text size="16px" className="padding-top-36 padding-bottom-24 mb-0">
                  One-page websites, landing pages for capturing new leads, e-commerce websites,
                  portfolios, you name it! No matter what you throw at our creative team, we’ll
                  accomplish each project with flying colors. Using our extensive background and
                  experience, we can take on any problem and turn even the most complicated idea
                  into a user-friendly, straightforward website that performs better than an F1
                  racecar.
                </Text>
                <List className="list-dashed" marginLeft="0px" paddingTop="0px" mobileFont="16px">
                  <li>Website Design & Development</li>
                  <li>Responsive Design</li>
                  <li>eCommerce Development & Web Applications</li>
                  <li>Digital Strategy</li>
                  <li>User Experience & Design</li>
                </List>
                <div className="padding-top-36  d-flex justify-content-start">
                  <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact us">
                    Build your website today!
                  </ButtonLink>
                </div>
              </div>
            </Fade>
          </div>
        </RowLeft>
      </CustomSection>

      {/* ################ */}

      <CustomSection
        tabletPadding="35px 0px"
        padding="0px"
        mobilePadding="0px"
        className="custom-container"
      >
        <RowRight className="row position-relative h-100 d-md-flex gutt-16 g-0 outt-40">
          <div className="margin-top-md-60 col-8 col-md-5 col-lg-5 offset-lg-1">
            <Fade bottom>
              <div>
                <Title
                  className="padding-top-180"
                  weight="700"
                  desktopsize="61px"
                  tabletsize="62px"
                  mobilesize="36px"
                  lineheightdesktop="61px"
                  lineheighttablet="62px"
                  lineheightmobile="43.2px"
                >
                  Custom-Made
                </Title>
                <Title
                  weight="400"
                  desktopsize="61px"
                  tabletsize="62px"
                  mobilesize="36px"
                  lineheightdesktop="61px"
                  lineheighttablet="62px"
                  lineheightmobile="43.2px"
                >
                  Apps
                </Title>
                <Text size="16px" className="padding-top-36 padding-bottom-24 mb-0">
                  Do you want to build the next TikTok and claim the social media throne? Maybe Zoom
                  for kittens? A new compression app that attracts rodents? Or Shazam for food? If
                  you have an idea for a new app, we’re all ears! No matter how complicated it may
                  seem, we’re up to the challenge. We’ve removed the word “impossible” from our
                  dictionary and replaced it with “mmm… possible!”
                </Text>
                <List className="list-dashed" marginLeft="0px" paddingTop="0px" mobileFont="16px">
                  <li>Immaculate Design & Smooth UI</li>
                  <li>Android/iOS Compatible</li>
                  <li>Business, Communications & Social Media Apps</li>
                  <li>Shopping & Productivity Apps</li>
                  <li>Sports, News & Magazines Apps</li>
                  <li>Anything You Have In Mind!</li>
                </List>
                <div className="padding-top-36  d-flex justify-content-start">
                  <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact us">
                    Build your mobile app today!
                  </ButtonLink>
                </div>
              </div>
            </Fade>
          </div>
          <div className="decoration-container-right col-8 col-md-3 col-lg-5">
            <BackgroundContainerRight role="img" aria-label="trapezoid background" />
            <div className="col-8 col-md-12 col-lg-12">
              {/* "decoration-container-img" */}
              <Fade left>
                <SVGImage
                  src={`services-apps.svg`}
                  className="decoration-right-img"
                  alt="Apps services"
                  height="auto"
                  width="auto"
                  style={{
                    backgroundPositionX: breakpoints.sm ? 'center' : 'right',
                    backgroundSize: 'auto 100%',
                    margin: 'auto',
                    height: breakpoints.md ? (breakpoints.sm ? 254.26 : 197) : 327,
                  }}
                />
              </Fade>
            </div>
          </div>
        </RowRight>
      </CustomSection>

      {/* ################ */}

      <CustomSection
        tabletPadding="35px 0px 180px 0px"
        padding="0px"
        mobilePadding="0px 0px 80px 0px"
        className="custom-container"
      >
        <RowLeft className="row position-relative h-100 d-md-flex gutt-16 g-0 outt-40">
          <div className="decoration-container col-8 col-md-3 col-lg-5 order-2 order-md-1">
            <BackgroundContainer role="img" aria-label="trapezoid background" />
            <div className="decoration-container-img col-8 col-md-12 col-lg-12">
              <Fade left>
                <SVGImage
                  src={`services-games.svg`}
                  className="decoration-left-img"
                  alt="Games services"
                  height="auto"
                  width="auto"
                  style={{
                    backgroundPositionX: breakpoints.sm ? 'center' : 'left',
                    backgroundSize: 'auto 100%',
                    margin: 'auto',
                    height: breakpoints.md ? (breakpoints.sm ? 254.26 : 197) : 327,
                  }}
                />
              </Fade>
            </div>
          </div>
          <div className="col-8 col-md-5 col-lg-5 offset-lg-1 order-1 order-md-2">
            <Fade bottom>
              <div>
                <Title
                  className="margin-top-md-100 margin-top-0"
                  weight="700"
                  desktopsize="61px"
                  tabletsize="62px"
                  mobilesize="36px"
                  lineheightdesktop="61px"
                  lineheighttablet="62px"
                  lineheightmobile="43.2px"
                >
                  Exceptional
                </Title>
                <Title
                  weight="400"
                  desktopsize="61px"
                  tabletsize="62px"
                  mobilesize="36px"
                  lineheightdesktop="61px"
                  lineheighttablet="62px"
                  lineheightmobile="43.2px"
                >
                  Games
                </Title>
                <Text size="16px" className="padding-top-36 padding-bottom-24 mb-0">
                  Whether you are looking to build the new Flappy Bird-like phenom or turn your idea
                  for an MMORPG into the new World Of Warcraft, we’re here to help you! Our team of
                  experienced developers and die-hard gamers will take all your rough ideas and
                  polish them into an aesthetically pleasing, responsive, and addictively fun mobile
                  game for any platform.
                </Text>
                <List className="list-dashed" marginLeft="0px" paddingTop="0px" mobileFont="16px">
                  <li>Stunning Graphics</li>
                  <li>Addictive Gameplay</li>
                  <li>Smooth User Experience</li>
                  <li>Action, Adventure & Arcade Games</li>
                  <li>Casual, Role-Playing & MOBA Games</li>
                  <li>Racing, Puzzle, Strategy, And Sports Games</li>
                </List>
                <div className="padding-top-36 d-flex justify-content-start">
                  <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact us">
                    Build your game today!
                  </ButtonLink>
                </div>
              </div>
            </Fade>
          </div>
        </RowLeft>
      </CustomSection>
    </Layout>
  );
};
export default Services;
